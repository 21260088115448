export interface IState {
	isStartPage: boolean
	logotypeURI: string
	startPageURI: string
	searchPageURI: string
	loginPageURL: string
	currentURL: string
	sitemapURI: string
	menuItems: IMenuItem[]
	endpoints: {
		getChildren: string
	}
}
export interface IMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number
}
export interface IMenuItemMain {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number
}


const deafultState: IState = {
	isStartPage: false,
	logotypeURI: '',
	startPageURI: '',
	searchPageURI: '',
	loginPageURL: '',
	currentURL: '',
	sitemapURI: '',
	menuItems: [],
	endpoints: {
		getChildren: ''
	}
}

export default deafultState;
